import {ImageList} from "./image-list";
import {DialogController, DialogService} from "aurelia-dialog";
import {WoundDataHandler} from "../../../views/patient/wunden/wound-data-handler";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {autoinject, bindable} from "aurelia-framework";
import {ModalBodyMap} from "../modal-body-map";
import {Patient} from "../../classes/FhirModules/Patient";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {PatientService} from "../../services/PatientService";
import {PatientInformation} from "../patient-overview/patient-information";
import {ConfigService} from "../../services/ConfigService";

@autoinject
export class assignWoundDialogContent {
    uxDialog;
    handler: WoundDataHandler;
    groups;
    encounterId;
    controller;
    @bindable patient : PatientItem;
    @bindable selectedGroup;

    selectedGroupChanged(newValue) {
    }

    selectedWound;

    constructor(controller: DialogController, protected dialogService : DialogService) {
        RuntimeInfo.IsLoading = true;
        this.controller = controller;
    }

    async activate(params) {
        this.handler = params.handler;
        this.encounterId = params.encounterId;
        this.patient = await PatientItem.Load(this.encounterId);
    }

    openGrafixx() {
        this.dialogService
            .open({
                viewModel: ModalBodyMap,
                model: {
                    patient: this.patient,
                },
            })
            .whenClosed(async (result) => {
                if (!result.wasCancelled) {
                    try {
                        RuntimeInfo.IsLoading = true;
                        this.groups = await this.handler.loadWounds(this.encounterId);
                    }
                    catch (e) {
                        console.warn(e);
                    }
                    finally {
                        RuntimeInfo.IsLoading = false;
                    }
                }
            })
            .catch(err => console.warn(err));
    }

    async attached() {
        this.resizeDialogue();
        this.groups = await this.handler.loadWounds(this.encounterId);
        RuntimeInfo.IsLoading = false;
        this.selectedGroup = this.groups[0];

        if (ConfigService.Debug)
            console.debug(this.groups);
    }

    resizeDialogue() {
        /*if (this.uxDialog) {
            this.uxDialog.style.height = `${window.innerHeight - 220}px`;
            this.uxDialog.style.width = `${window.innerWidth - 120}px`;
        }*/
    }
}
