import {FrameworkConfiguration, PLATFORM} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
    config.globalResources([
        PLATFORM.moduleName('./elements/questionnaire/questionnaire'),
        PLATFORM.moduleName('./attributes/datepicker'),
        PLATFORM.moduleName('./elements/patient-overview/patient-spi-spider-web'),
        PLATFORM.moduleName('./value-converters/dateFormatValueConverter'),
        PLATFORM.moduleName('./value-converters/dualNumberValueConverter'),
        PLATFORM.moduleName('./value-converters/objectToStringValueConverter'),
        PLATFORM.moduleName('./value-converters/toArrayValueConverter'),
        PLATFORM.moduleName("./elements/text-input"),
        PLATFORM.moduleName('./elements/tabbed-form-header'),
        PLATFORM.moduleName('./elements/save-button'),
        PLATFORM.moduleName('./elements/loading-button')
    ]);
}
