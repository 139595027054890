import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {FhirService} from "../../services/FhirService";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {ImageList} from "./image-list";
import {I18N} from "aurelia-i18n";
import {WoundGroup} from "../../../views/patient/wunden/wound-main";
import {WoundDataHandler} from "../../../views/patient/wunden/wound-data-handler";

const moment = require("moment");

@autoinject
export class ImagesHistory {
    uxDialog;
    patient: PatientItem;
    encounterId;
    woundId;
    displayCompleteList : boolean = false;
    displayCompleteList2 : boolean = false;
    maximizeLeftView : boolean = false;
    protected dataHandler: WoundDataHandler;
    protected fhirService : FhirService;
    constructor(protected controller: DialogController, protected i18n : I18N) {
        this.fhirService = new FhirService(FhirService.Endpoint);
        this.dataHandler = new WoundDataHandler(i18n);
    }

    activate(params) {
        ImageList.imageCache = {};
        this.patient = params.patient;
        this.encounterId = params.encounterId;
        this.woundId = params.woundId;
    }

    deactivate() {
        ImageList.imageCache = {};
    }

    attached() {
        this.resizeDialogue();
    }

    resizeDialogue() {
        if (this.uxDialog) {
            this.uxDialog.style.height = `${window.innerHeight - 220}px`;
            this.uxDialog.style.width = `${window.innerWidth - 120}px`;
        }
    }
}


export interface ImageEncounter extends fhir3.Encounter {
    wounds? : WoundGroup[];
}
