import {autoinject, bindable, bindingMode, inject, TaskQueue} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {PromptInput} from "./prompt-input";
import {translations} from "../classes/translations";
import {I18N} from "aurelia-i18n";

const moment = require("moment");
import {PatientItem} from "../classes/Patient/PatientItem";
import {FhirService} from "../services/FhirService";
import {PatientChangeNotifier} from "resources/services/PatientChangeNotifier";
import {ConfigService} from "../services/ConfigService";
import {FormBaseClass} from "./FormBaseClass";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {saveButton} from "./save-button";
import {QuestionnaireService} from "../services/QuestionnaireService";

@autoinject
export class tabbedFormHeader {
    hasResponses: boolean = false;
    tabHolder: HTMLDivElement;
    @bindable({defaultBindingMode: bindingMode.twoWay}) selectedid: string;
    @bindable responses: any[];
    baseElement: HTMLDivElement;
    @bindable showtime: boolean;
    @bindable datechanged: Function;
    @bindable patient: PatientItem;
    @bindable onResponseidChanged: Function;
    @bindable parent;

    constructor(protected taskQueue: TaskQueue,
                protected dialogService: DialogService,
                protected i18n: I18N,
                protected patientChangeNotifier: PatientChangeNotifier,
                protected fhirService: FhirService) {

    }

    responsesChanged(newVal: any[]) {
        this.hasResponses = newVal && newVal.length > 0;

        if (this.hasResponses) {
            this.taskQueue.queueTask(() => {
                let last = newVal[newVal.length - 1];
                if (last) {
                    this.selectedid = last.id;
                }
            })
        }
    }

    selectedidChanged(newId: string) {
        // document.querySelectorAll(".tab-header[data-response-id]").forEach(e => e.classList.remove("active"));
        let tab = document.querySelector(`.tab-header[data-response-id="${newId}"]`);
        // tab.classList.add("active");
        let $tab = $(tab);

        let offset = $tab.position();
        if (!offset) return;
        let x = this.tabHolder.scrollLeft + offset.left;
        x -= ($(this.tabHolder).innerWidth() / 2) - ($tab.outerWidth() / 2);
        $(this.tabHolder).stop().animate({'scrollLeft': x});
    }

    @bindable changed: Function;

    useStatus: boolean = FormBaseClass.UseQuestionnaireStatusForSave;

    @bindable dateChangeable: boolean;

    changeAuthoredDate(response: any, e) {
        e.preventDefault();
        if (!this.dateChangeable || response.id !== this.selectedid) return; // only change date for currently selected tab!

        let oldVal = moment(response.authored).format(RuntimeInfo.DateTimeFormat);
        this.dialogService.open({
            viewModel: PromptInput,
            model: {
                message: this.i18n.tr("tab_header_enter_date"),
                title: translations.translate('information'),
                yesText: translations.translate("ok"),
                noText: translations.translate('abort'),
                inputType: "date",
                value: response.authored
            },
            lock: true
        })
            .whenClosed(async dialogResult => {
                if (dialogResult.wasCancelled) return;

                let newDate = RuntimeInfo.IsMobile ? new Date(dialogResult.output).toJSON() : moment(dialogResult.output, RuntimeInfo.DateTimeFormat).toJSON();
                try {
                    RuntimeInfo.IsLoading = true;
                    response.authored = response.authored ? response.authored : newDate;
                    let existing = this.responses.find(o => o.id === response.id);
                    if (existing) {
                        existing.authored = response.authored;
                    }

                    if (this.patient) {
                        try {
                            this.patient.questionnaireResponses.find(o => o.id === response.id).authored = newDate;
                        } catch (e) {
                            console.warn(e.message || e);
                        }
                    }

                    let newVal = moment(response.authored).format(RuntimeInfo.DateTimeFormat);
                    if (newVal === oldVal) {
                        RuntimeInfo.IsLoading = false;
                        if (ConfigService.Debug) {
                            console.debug("Value has not been changed, no need to update response", response);
                        }

                        return;
                    }

                    await this.fhirService.update(response);
                    this.patientChangeNotifier.notify(this.patient);
                    RuntimeInfo.IsLoading = false;
                    if (typeof this.changed === "function") {
                        this.changed(newDate);
                    }
                } catch (e) {
                    RuntimeInfo.IsLoading = false;
                    console.warn("Error when updating authored date:", e.message || e);
                }
            });

        return false;
    }

    tabScroll(offset: number) {
        let pos = this.tabHolder.scrollLeft;
        pos += offset;
        if (pos < 0) {
            pos = 0;
        }

        if (pos > this.tabHolder.scrollWidth) {
            pos = this.tabHolder.scrollWidth;
        }

        $(this.tabHolder).stop().animate({'scrollLeft': pos});
    }

    selectResponse(response: any) {
        // this.selectedid = response.id;
        if (typeof this.onResponseidChanged === "function") {
            this.onResponseidChanged(response.id);
        }
    }

    getPencilClass(response): string {
        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(response?.questionnaire);
        if (!questionnaire) return undefined;

        if (['completed', 'amended'].indexOf(questionnaire.status) -1 )
            return "mdi-lock";

        return 'mdi-edit';
    }

    attached() {
        if (typeof this.showtime === "undefined") {
            this.showtime = true;
        }

        this.useStatus = true;
    }
}
