import {bindable} from "aurelia-framework";
import {qBase} from "./q-base";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {NitTools} from "../../classes/NursitTools";

export class QBoolean extends qBase {
    @bindable response : any;
    @bindable previousresponse: any;
    @bindable item: any;
    @bindable changed: Function;
    @bindable readonly : boolean;
    responseItem: any;
    value: boolean = undefined;
    prevItem : any;
    prevValue : any;
    
    onItemValueChangeNotified(item) {
        super.onItemValueChangeNotified(item);
        this.getResponseItem();
    }

    getResponseItem() {
        if (!this.item || !this.response) {
            this.responseItem = undefined;
            return;
        }

        this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
        if (this.responseItem) {
            let val = Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem, undefined);

            if (typeof val !== "undefined") {
                if (typeof val === "string" && val.indexOf('_') > -1) val = val.endsWith('2');
                this.value = val;
                this.setNewValue(this.value);
            }
        }

        if (this.previousresponse) {
            this.prevItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.previousresponse, this.item.linkId);
            if (this.prevItem) {
                let pVal = Fhir.QuestionnaireResponse.GetResponseItemValue(this.prevItem);
                if (typeof pVal === 'string' && pVal.indexOf('_') > -1) pVal = pVal.endsWith('2');
                this.prevValue = pVal;
            }
        }
    }

    previousresponseChanged() {
        this.getResponseItem();
    }

    itemChanged() {
        this.getResponseItem();
    }

    responseChanged() {
        this.getResponseItem();
    }

    setNewValue(val: boolean) {
        if (this.readonly || typeof val === "undefined") return;
        if (!this.responseItem) {
            console.warn("Update without responseItem!");
            return;
        }

        this.value = val;

        this.responseItem.answer = [
            {
                valueBoolean: this.value
            }
        ];

        if (typeof this.changed === "function") {
            this.changed(this.responseItem);
        }
    }
}
