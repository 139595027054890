import {bindable} from "aurelia-framework";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {fhirEnums} from "../../classes/fhir-enums";
import {NitTools} from "../../classes/NursitTools";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {CiwQuestionnaireItem} from "./ciw-questionnaire-item";

export class qInput {
    @bindable response: any;
    @bindable item: CiwQuestionnaireItem;
    @bindable changed: Function;
    @bindable readonly: boolean;
    @bindable hidePlaceholder: boolean;

    inputType: string = "text";
    readonlyBySetting: boolean = false;
    required: boolean = false;
    _isCalulcatedField: boolean = undefined;
    responseItem: any = undefined;

    get placeHolderText(): string {
        if (this.hidePlaceholder) return "";
        return this.item ? this.item.text : "";
    }

    get value() {
        let result = '';
        
        if (this.responseItem && this.item) {
            if (this.responseItem.answer && this.responseItem.answer.length > 0) {
                result = Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem);
                if (typeof result === "string" && (String(result)).endsWith('_nil')) {
                    result = '';
                }
            }
        }

        return result;
    }

    set value(newValue) {
        const curVal = String(Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem) || '');
        if (String(newValue || '') == curVal || (!newValue && curVal === 'NaN') || (newValue === 'NaN' && curVal==='')) return;

        if (!this.responseItem && this.item && this.response) {
            this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId);
        }

        if (!this.responseItem) return;

        if (typeof newValue === "undefined" || newValue === '' || ((typeof newValue === "string") && (String(newValue).endsWith('_nil')))) {
            delete this.responseItem.answer;
        } else {
            let itemAnswer: any = {
                valueString: newValue
            }

            if (this.item) {
                if (this.item.type === 'integer') {
                    itemAnswer = {
                        valueInteger: parseInt(newValue)
                    }
                } else if (this.item.type === 'decimal') {
                    itemAnswer = {
                        valueDecimal: parseFloat(newValue),
                    }
                } else if (this.item.type === 'url') {
                    itemAnswer = {
                        valueUri: String(newValue),
                    }
                }
            }

            this.responseItem.answer = [itemAnswer];
        }

        if (typeof this.changed === "function") {
            // goes down to questionnaire.onValueChanged
            this.changed(this.responseItem);
        }
    }

    itemBlurred() {
        // update once again explicitely, because in some cases the simple value update does not work
        if (!this.item || !this.response || this.readonly) return;
        if (typeof this.value === "undefined") return;

        let fi = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);

        if (!String(this.value) || String(this.value).trim() === "") {
            if (fi) {
                delete fi.answer;
                delete fi.text;
            }

            return;
        }

        let answer: any = undefined;

        if (this.item.type === fhirEnums.QuestionnaireItemType.decimal) {
            answer = {
                valueDecimal: parseFloat(this.value)
            }
        } else if (this.item.type === fhirEnums.QuestionnaireItemType.integer) {
            answer = {
                valueInteger: parseInt(this.value)
            }
        } else {
            answer = {
                valueString: this.value
            }
        }

        fi.answer = [answer];
    }

    responseChanged() {
        this.getInitialValue();
    }

    itemChanged() {
        this.getInitialValue();
    }

    get isCalulcatedField() {
        if (typeof this._isCalulcatedField === "boolean") return this._isCalulcatedField;

        if (!this.item) return false;

        this._isCalulcatedField = false;

        if (this.item && this.item.extension) {
            let extCalc = this.item.extension.find(o => o.url.endsWith('questionnaire-calculated-field') && o.valueString);
            if (extCalc) {
                this.item.initialCoding = {
                    code: "=" + extCalc.valueString,
                }
            }
        }

        if (this.item && this.item.initialCoding && this.item.initialCoding.code) {
            let tmp = this.item.initialCoding.code.trim();
            if (tmp.indexOf("=") === 0) {
                this._isCalulcatedField = true;
            }
        }

        return this._isCalulcatedField;
    }

    maxLength: number = NaN;

    get hasMax() {
        return (typeof this.item.maxLength === "number" && this.item.maxLength && typeof this.maxLength === "number" && this.maxLength > 0);
    }

    get requiredText(): string {
        return this.required ? 'required="required"' : '';
    }

    getInitialValue() {
        // if (!this.isCalulcatedField) return;
        if (!this.item || !this.response) {
            this.responseItem = undefined;
            this.value = "";
            return;
        }

        this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
        this.value = this.responseItem && this.responseItem.answer ? Fhir.QuestionnaireResponse.GetItemAnswer(this.responseItem.answer[0], undefined) : undefined;
        this.readonlyBySetting = NitTools.ParseBool(this.item.readOnly);
        this.required = NitTools.ParseBool(this.item.required);
        if (typeof this.item.maxLength !== "undefined") {
            this.maxLength = this.item.maxLength;
        }

        if (!this.isCalulcatedField && // no initialvalue on calc fields and not when the item has an answer
            !this.value) {
            /* if (this.item.initialCoding && this.item.initialCoding.code) {
                this.value = this.item.initialCoding.code;
            } */
        }

        if (this.isCalulcatedField) {
            this.inputType = "text";
        } else {
            switch (this.item.type) {
                default:
                case "text":
                    this.inputType = "text";
                    break;
                case "integer":
                case "decimal":
                    this.inputType = "number";
                    break;
                case "date":
                    console.warn("Date-Input should never appear instead it should be handeled by q-date control");
                    this.inputType = "text";
                    break;
                case "time":
                    this.inputType = RuntimeInfo.IsIE ? "text" : "time";
                    break;
                case "dateTime":
                    this.inputType = RuntimeInfo.IsIE ? "text" : "datetime-local";
                    break;
            }
        }
    }
}
