import {bindable} from "aurelia-framework";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {ConfigService} from "../../services/ConfigService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {add} from "lodash";

const moment = require("moment");

export class qDate {
    @bindable changed: Function;
    @bindable readonly;
    @bindable placeholder;
    @bindable value;
    @bindable mobileDate;

    @bindable picker;

    pickerChanged() {
        // disable Sunday & Saturday
        //this.picker.methods.daysOfWeekDisabled([0,6]);
        /*this.picker.events.onChange = (e) => this.pickerChange(e);
        this.picker.events.onUpdate = (e) => console.log('onUpdate'); */
    }

    get isReadonly() {
        if (this.item && this.item.readOnly) return true;
        // return from form
        return this.readonly;
    }

    isMobile: boolean;
    inputType: string = "text";
    _isCalulcatedField: boolean = undefined;
    responseItem: any = undefined;
    format: string = RuntimeInfo.DateFormat; // translations.translate("date_format");
    @bindable item: any;

    itemChanged() {
        this.checkResponseItem();
    }

    @bindable response: any;

    responseChanged() {
        this.checkResponseItem();
    }

    pickerChange(e) {
        console.warn('dp onChange', e)
    }

    valueChanged(newDate) {
        if (!this.response || !this.responseItem) return;
        if (!newDate || newDate === null) {
            delete this.responseItem.answer;
        } else {
            let dateVal = new Date(newDate);
            this.responseItem.answer = [{
                valueDate: this.formatDate(dateVal)
            }];
            this.responseItem.text = this.item.text;
        }

        if (typeof this.changed === "function") this.changed(this.responseItem);
    }

    private makeTwo(num: number): string {
        let s = String(num);
        if (s.length < 2) {
            s = "0" + s;
        }

        return s;
    }

    private formatDate(date: Date) {
        if (typeof date === "undefined" || date === null) return undefined;
        else if (typeof date === "string") date = moment(date).toDate();
        return `${date.getFullYear()}-${this.makeTwo(date.getMonth() + 1)}-${this.makeTwo(date.getDate())}`;
    }

    checkResponseItem() {
        this.responseItem = undefined;
        this.value = null;
        let dateVal: Date = null;

        this.isMobile = RuntimeInfo.IsMobile;
        let itemValue = undefined;
        if (this.response && this.item) {
            this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
            try {
                itemValue = Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem);
                if (typeof itemValue === "object" || typeof itemValue === "string") {
                    dateVal = new Date(itemValue);
                    this.lastDate = moment(dateVal).format('YYYY-MM-DD');
                } else {
                    dateVal = null;
                }

                if (!this.isMobile) {
                    this.value = dateVal;
                } else {
                    this.mobileDate = this.formatDate(dateVal);
                }
            } catch (e) {
                console.warn(`Error when setting value to '${itemValue}': ` + e.message || JSON.stringify(e));
                this.value = null;
            }
        }
    }

    mobileInput: HTMLInputElement;

    mobileBlurred() {
        this.mobileDateChanged(this.mobileInput.value);
    }

    lastDate: any = undefined;

    get valueDate(): string {
        if (this.responseItem && this.responseItem.answer && this.responseItem.answer[0] && this.responseItem.answer[0].valueDate) {
            let s = String(this.responseItem.answer[0].valueDate);
            if (/([12][90]\d\d)-([0123]\d)-([0123]\d)/.test(s)) {
                s = moment(this.responseItem.answer[0].valueDate, 'YYYY-MM-DD').format(RuntimeInfo.DateFormat);
            } else {
                s = moment(this.responseItem.answer[0].valueDate).format(RuntimeInfo.DateFormat);
            }

            return s;
        }

        return '';
    }

    set valueDate(val: string) {
        if (!this.responseItem) return;
        if (!val || val === null || typeof val === 'undefined' || val === '')
            delete this.responseItem.answer;
        else
            this.responseItem.answer = [
                {valueDate: moment(val, RuntimeInfo.DateFormat).format('YYYY-MM-DD')}
            ];

        if (this.item) this.responseItem.text = this.item.text;

        if (typeof this.changed === "function") this.changed(this.responseItem);
    }

    isDate(newValue): boolean {
        if (typeof newValue === "string")
            return /([123456789]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(newValue);
        else if (typeof newValue === "object") return true;
    };

    mobileDateChanged(newValue) {
        if (!this.responseItem) return;
        if (!this.isDate(newValue) || !newValue || newValue === null) {
            delete this.responseItem.answer;
            delete this.responseItem.text;
        } else {
            try {
                let date = typeof newValue === "string" ? new Date(newValue) : newValue;
                this.responseItem.answer = [
                    {
                        valueDate: this.formatDate(date)
                    }
                ]
            } catch (e) {
                console.warn(e.message || JSON.stringify(e));
            }
        }

        if (typeof this.changed === "function") this.changed(this.responseItem);
    }

    get isCalulcatedField() {
        if (typeof this._isCalulcatedField === "boolean") return this._isCalulcatedField;

        if (!this.item) return false;

        this._isCalulcatedField = false;
        if (this.item && this.item.initialCoding && this.item.initialCoding.code) {
            let tmp = this.item.initialCoding.code.trim();
            if (tmp.indexOf("=") === 0) {
                this._isCalulcatedField = true;
            }
        }

        return this._isCalulcatedField;
    }

    focused() {
        // console.debug("focused");
    }

    dpOptions = {
        format: RuntimeInfo.DateFormat, // this.format,
        defaultDate: false,
        useCurrent: false,
        icons: {close: 'glyphicon glyphicon-ok'},
        // see: https://getdatepicker.com/4/Options/#parseinputdate
        parseInputDate: (e: string) => {
            let result = typeof (e) === 'string' ? moment(e, RuntimeInfo.DateFormat) : moment(e);

            if (e && typeof (e) === 'string') {
                e = e.toUpperCase().trim();

                const regex = /^([\+-])(\d*)([YWJMDT])?$/;

                if (regex.test(e)) {
                    let m = e.match(regex);

                    const adder = m[1];
                    const reValue = m[2];
                    const reScale = m[3]||"D";

                    let scale = 'days';
                    if (reScale === 'M') {
                        scale = 'months'
                    } else if (reScale === 'W') {
                            scale = 'weeks'
                    } else if (reScale === 'Y' || reScale === 'J') {
                        scale = 'years'
                    }

                    let n = parseInt(reValue);
                    if (n) {
                        if (adder === '-') n = -n;

                        result = moment(new Date()).add(n, scale);
                        this.picker.methods.viewDate(result);
                        this.picker.methods.defaultDate(result);
                    }
                }
            }

            return result;
        }
        // see App.setPickerOptions / App.pickerConfig
    };
}
