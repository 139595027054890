import {bindable} from "aurelia-framework";
import {NitTools} from "../../classes/NursitTools";
import {qBase} from "./q-base";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {qMultiSelect} from "./q-multi-select";

export class QOpenChoice extends qBase {
    @bindable changed;
    @bindable response: any;
    @bindable previousresponse: any;
    @bindable item: any;
    @bindable readonly;
    @bindable placeholder: string;
    @bindable vabutton : boolean;
    public multiSelect;
    public comboBox;
    @bindable onResponseItemValueChangedFromBehind : Function;

    get isReadonly() {
        if (this.item && this.item.readOnly) return true;
        // return from form
        return this.readonly;
    }

    itemValue: string | string[];

    onItemValueChangeNotified(item: any) {
        super.onItemValueChangeNotified(item);

        this.selectValues();
    }

    getOptionText(value: string): string {
        if (!this.item) return undefined;
        let option = this.options.find(o => o.value === value);
        if (option) {
            return option.hint || option.text;
        }
    }

    valueChanged() {
        if (!this.responseItem) return;
        let txt: string = undefined;
        if (NitTools.IsArray(this.itemValue)) {
            let tmpAray: string[] = [];
            (<string[]>this.itemValue).forEach(s => {
                let tmp: string = this.getOptionText(s);
                if (tmp || "" !== "") {
                    tmpAray.push(tmp);
                }
            });

            txt = tmpAray.join(", ");
        } else {
            if (typeof this.itemValue !== "undefined") {
                txt = this.getOptionText(String(this.itemValue));
            }
        }

        if (typeof this.itemValue !== "undefined") {
            this.responseItem.answer = [
                {
                    valueCoding: {
                        code: String(this.itemValue),
                        display: txt
                    }
                }
            ];
        } else {
            delete this.responseItem.answer;
        }

        if (typeof this.changed === "function") {
            this.changed(this.responseItem);
        }
    }

    exclusiveUrlPart: string = "questionnaire-optionExclusive";

    itemChanged(item: any) {
        if (!item) {
            this.isMultiSelect = false;
            return;
        }

        if (item["answerOption"])
            item.option = item["answerOption"];

        this.isMultiSelect = item.repeats;

        this.createOptions();
        this.selectValues();
    }

    responseChanged(newVal) {
        if (newVal) this.selectValues();
        else {
            this.itemValue = undefined;
        }
    }

    responseItem: any = undefined;

    options: any[] = [];
    isMultiSelect: boolean = false;

    // multiSelect: MultiSelect = undefined;

    selectValues() {
        this.responseItem = undefined;
        this.itemValue = undefined;

        if (!this.response || !this.item || !this.item.linkId) {
            return;
        }

        this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);

        if (!this.responseItem || !this.responseItem.answer) {
            return;
        }

        this.itemValue = [];

        this.responseItem.answer.forEach(a => {
            if (a.valueCoding && a.valueCoding.code) {
                (<string[]>this.itemValue).push(a.valueCoding.code);
            }
        });

        this.valueChanged();
    }

    createOptions() {
        if (!this.item || !this.item.option) return;

        // if (!this.itemValue) this.itemValue =  `${this.item.linkId}_nil`;

        this.options = [];

        // add a disabled item
        if (!RuntimeInfo.IsMobile && this.placeholder) {
            let ph = `${this.placeholder}`.replace("...", "").replace(":", "").replace('*', '');
            this.options.push({
                text: ph,
                hint: this.item.text,
                disabled: true
            });
        }

        // create a _nil item if none set
        if (!this.item.option.find((o => o.valueCoding.code.endsWith('_nil')))) {
            this.options.push({
                text: ' ',
                hint: this.item.text,
                value: this.item.linkId + "_nil"
            });
        }

        // add the rest from the item
        (<any[]>this.item.option).filter(o => o.valueCoding && o.valueCoding.code).forEach(optionItem => {
            // http://hl7.org/fhir/StructureDefinition/questionnaire-hidden
            let hidden = false;
            if (optionItem.extension) {
                let hiddenExtension: any = optionItem.extension.find(o => o.url.endsWith('questionnaire-hidden'));
                if (hiddenExtension && typeof hiddenExtension.valueBoolean === "boolean") {
                    hidden = hiddenExtension.valueBoolean === true;
                }
            }


            let newOption = {
                value: optionItem.valueCoding.code,
                text: optionItem.valueCoding.display || "",
                hint: optionItem.valueCoding.display || ""
            };

            if (newOption.text.indexOf('|') > -1) {
                let arr = newOption.text.split('|');
                newOption.text = arr[0];
                newOption.hint = arr[1];
            }

            if (optionItem.extension) {
                let h = Fhir.Tools.GetExtensionValue(Fhir.Tools.GetOrCreateExtension(optionItem, 'questionnaire-option-hint', false));
                if (typeof h !== "undefined" && String(h) !== "") {
                    newOption.hint = h;
                }
            }

            if (!hidden)
                this.options.push(newOption);
        });
    }
}
