import {bindable} from "aurelia-framework";
import {NitTools} from "../../classes/NursitTools";
import {qChangeNotifier} from "./q-changeNotifier";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {Questionnaire, QuestionnaireResponse} from "../../classes/FhirModules/Fhir";
import {DialogService} from "aurelia-dialog";
import {ModalBodyMap} from "../modal-body-map";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {PatientService} from "resources/services/PatientService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {ConfigService} from "../../services/ConfigService";
import {parseInt} from "lodash";
import {HttpClient} from "aurelia-http-client";
import {UserService} from "../../services/UserService";
import {CiwQuestionnaireItem} from "./ciw-questionnaire-item";
import {QOpenChoice} from "./q-open-choice";
import {QChoice} from "./q-choice";
import {QBoolean} from "./q-boolean";
import {qInput} from "./q-input";
import {Modal3dBody} from "../modal-3dbody";

// noinspection JSUnusedGlobalSymbols
// noinspection JSUnusedSymbols
export class QItem {
    static inject = [qChangeNotifier, DialogService, PatientService];
    static qList: IQuestionnaireList = undefined;
    private static Presets: any[] = [{
        name: 'default_empty_item',
        valid: false,
        response: {}
    }];

    @bindable item: CiwQuestionnaireItem;
    @bindable response: any;
    @bindable previousresponse: any;
    @bindable changed: Function;
    @bindable questionnaire;
    @bindable readonly;
    @bindable encounter: string;
    @bindable refclicked;
    @bindable showgroupheaderbuttons: boolean;
    @bindable showtargetbuttons: boolean;
    @bindable patient: PatientItem;
    @bindable p2;
    @bindable hidePlaceholder: boolean;
    @bindable responseItem: any = undefined;
    @bindable showTrashcan: boolean;
    @bindable designing: boolean;
    patientService: PatientService;
    hasHeaderButtons: boolean = false;
    caption: string = "";
    componentName: string = "QI_" + NitTools.Uid().replace(/-/g, "_");
    notifier: qChangeNotifier;
    isVisible: boolean = true;
    displayItem: any = undefined;
    children = [];
    dialogService: DialogService;
    linkUrl: string;
    infoHtml: string = undefined;
    isLink: boolean = false;
    visibleItemText;
    formElement;
    groupElement: HTMLDivElement;
    showInfo: boolean = false;
    isGrafixxItem: boolean = false;
    isPipeItem: boolean = false;
    isAllergyList: boolean = false;
    isResponseList: boolean = false;
    isNursITHidden: boolean = false;
    isObservationItem: boolean = false;
    itemValueChanged: Function; // should reach into the form item to trigger an update of display. Mostly for MultiSelect

    @bindable currentControl;
    @bindable public enableWhenValue: boolean;
    oldVal;
    @bindable processEnableWhenChanges: boolean = false;

    constructor(notifier: qChangeNotifier, dialogService: DialogService, patientService: PatientService) {
        this.notifier = notifier;
        this.dialogService = dialogService;
        this.patientService = patientService;
    }

    _hasInfoText?: boolean = undefined;

    get hasInfoText() {
        if (typeof this._hasInfoText === "boolean") return this._hasInfoText;

        if (this.item && this.item.extension && NitTools.IsArray(this.item.extension)) {
            let extInfo = this.item.extension.find(o => o.url.endsWith('questionnaire-item-comment'));
            if (extInfo && extInfo.valueString) {
                let parts = extInfo.valueString.replace('\n', '').replace('<br>', '<br />').split('<br />');
                let s = "";
                parts.forEach(part => s += `<text>${part}</text>`);

                this.infoHtml = s; // extInfo.valueString;

                this._hasInfoText = true;

                return this._hasInfoText;
            }
        }

        if (typeof this._hasInfoText === "boolean") return this._hasInfoText;

        if (!this.questionnaire || !this.item || !this.item.linkId || !this.item.type) {
            return false;
        }

        if (this.item.type === "group") {
            this._hasInfoText = false;
            return this._hasInfoText;
        }

        let displayId = `Display_${this.item.linkId}`;
        this._hasInfoText = false;
        this.displayItem = <any>Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.questionnaire, displayId, false);
        if (this.displayItem) {
            this._hasInfoText = true;
        }

        return this._hasInfoText;
    }

    get required(): boolean {
        return this.item && this.item.required;
    }

    get placeHolderText(): string {
        if (this.hidePlaceholder) return "";
        return this.item ? this.item.text : "";
    }

    get headerBgColor(): string {
        if (this.readonly || !this.item?.qualificationFulfilled)
            return "#757575"
        else {
            const result = this.item?.groupColor && this.item?.groupColor !== 'inherited' ? this.item.groupColor : 'var(--panel-heading-background)';
            return result;
        }
    }

    get displayNoLinkLabel() {
        return this.item && ["date", "time", "dateTime",
            "decimal", "integer", "string", "text",
            "url"].indexOf(this.item.type) === -1;
    }

    get enableWhenSatisfied() {
        this.enableWhenValue = Fhir.Tools.IsEnableWhenSatisfied(this.item, this.response);
        if (this.patient && this.item && this.item.linkId === ConfigService.PkmsGroupName)
            this.enableWhenValue = this.patient.pkms_relevant;

        return this.enableWhenValue;
    }

    get showTrash(): boolean {
        if (typeof this.showTrashcan === "boolean") return this.showTrashcan;
        else return true;
    }

    get typeHandled() {
        if (!this.item) return false;
        return ['text', 'reference', 'decimal', 'boolean', 'choice', 'string', 'date', 'time', 'integer', 'open-choice', 'display'].indexOf(this.item.type) > -1;
    }

    get isSimpleInput() {
        if (!this.item) return false;
        return ['decimal', 'string', 'integer', 'text'].indexOf(this.item.type) > -1;
    }

    getCurrentQuestionnaireControl(): any {
        return this.getClassFromControl(this.currentControl);
    }

    /**
     * Is triggered when the value of enableWhenValue has been updated. Sets the Initial values in the control recursively if not already a value has been provided
     * @param newValue
     * @param oldValue
     */
    async enableWhenValueChanged(newValue, oldValue) {
        if (!this.processEnableWhenChanges || !newValue || newValue === oldValue || !this.response || !this.item) return;

        let updateItems = this.getChildItems(this.item);
        for (const linkId of updateItems) {
            const responseItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, linkId);
            const questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);
            if (!responseItem || !questionnaireItem || questionnaireItem.type === 'group') continue;

            const ctrl = this.getCurrentQuestionnaireControl();
            if (NitTools.IsArray(questionnaireItem.initial)) {
                // don't overwrite already existing answers with default values
                let setAnswer = true;
                if (NitTools.IsArray(responseItem.answer) && responseItem.answer.length > 0) {
                    const coding = QuestionnaireResponse.GetResponseAnswerValue(responseItem.answer[0]);
                    setAnswer = !!!coding;
                }

                if (setAnswer)
                    responseItem.answer = NitTools.Clone(questionnaireItem.initial);

                if (responseItem.answer?.[0]?.valueCoding?.code) {
                    switch (questionnaireItem.type) {
                        case 'choice':
                            const qChoice = document.querySelector(`q-choice[data-link-id="${linkId}"]`);
                            if (qChoice) {
                                const choice: QChoice = this.getClassFromControl(qChoice);
                                if (choice) {
                                    choice.setItemValue(responseItem.answer[0].valueCoding.code, true, true);
                                }
                            }
                            break;
                        case 'open-choice':
                            const qOpenChoice = document.querySelector(`q-open-choice[data-link-id="${linkId}"]`);
                            if (qOpenChoice) {
                                const choice: QOpenChoice = this.getClassFromControl(qOpenChoice);
                                if (choice) {
                                    if (choice.isMultiSelect) {
                                        choice.selectValues();
                                    } else {
                                        choice.itemValue = responseItem.answer[0].valueCoding.code;
                                    }
                                }
                            }
                            break;
                        case 'boolean':
                            const htmlBoolean = document.querySelector(`q-boolean[data-link-id="${linkId}"]`);
                            if (htmlBoolean) {
                                const qBoolean: QBoolean = this.getClassFromControl(htmlBoolean);
                                if (qBoolean) {
                                    qBoolean.value = responseItem.answer[0].valueBoolean;
                                }
                            }
                            break;
                        case 'string':
                        case 'text':
                        case 'integer':
                        case 'decimal':
                            const htmlInput = document.querySelector(`q-input[data-link-id="${linkId}"]`);
                            if (htmlInput) {
                                const qInput: qInput = this.getClassFromControl(htmlInput);
                                if (qInput) {
                                    const a = responseItem.answer[0];
                                    qInput.value = a.valueString || a.valueDecimal || a.valueInteger;
                                }
                            }
                            break;
                    }
                }

                // handle multiselect value and update
                if (NitTools.IsArray(ctrl?.itemValue)) {
                    for (const answer of responseItem.answer) {
                        ctrl.itemValue.push(QuestionnaireResponse.GetResponseAnswerValue(answer));
                    }
                }

                if (typeof ctrl?.updateItemDisplay === "function") {
                    ctrl.updateItemDisplay();
                }
            }
        }

        if (typeof this.changed === "function") {
            this.changed(this);
        }
    }

    setDefaultResponseItemAnswersRecursively(responseItem: fhir4.QuestionnaireResponseItem) {
        if (!this.questionnaire || !responseItem?.linkId) return;
        const questionnaireItem: fhir4.QuestionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, responseItem.linkId);
        if (!questionnaireItem) return;


        if (questionnaireItem.type !== 'group') {
            if (!responseItem.answer) responseItem.answer = [];

            if (questionnaireItem.initial && responseItem.answer.length === 0) {
                const r = QuestionnaireResponse.GetResponseItemByLinkId(this.response, responseItem.linkId);
                r.answer = NitTools.Clone(questionnaireItem.initial);
                responseItem.answer = NitTools.Clone(questionnaireItem.initial);
            }
        }

        if (responseItem.item) {
            for (const child of responseItem.item) {
                this.setDefaultResponseItemAnswersRecursively(child);
            }
        }
    }

    callChange($event) {
        if (typeof this.changed === "function") {
            this.changed($event);
        }
    }

    valueChanged($event) {
        if ($event?.linkId)
            this.toggleInfoDisplay($event.linkId, false);
        this.callChange($event);
    }

    responseItemChanged() {
        if (!NitTools.IsArray(this.responseItem?.answer)) return;
        this.oldVal = JSON.stringify(this.responseItem.answer);
    }

    async responseChanged(newResponse) {
        if (!newResponse) {
            this.responseItem = undefined;
            this.hasHeaderButtons = false;
            return;
        }

        if (this.item && this.item.linkId && newResponse) {
            if (this.item.type !== "group" && this.item.type !== "display") {
                this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(newResponse, this.item.linkId, true);
            } else if (this.item.type === "group") {
                if (typeof QItem.qList === "undefined") QItem.qList = await QuestionnaireService.GetQuestionnaireIds();
                this.checkVisibility();
                this.updateHeaderButtons();
            }
        }
    }

    async itemChanged() {
        this.updateItem();
        this.isGrafixxItem = this.checkIsGrafixxItem(this.item);
        this.isPipeItem = this.checkIsPipeItem();
        this.isAllergyList = !!(this.item?.extension?.find(o => o.url?.endsWith('/questionnaire-allergy-list')));
        this.isObservationItem = !!(this.item?.extension?.find(o => o.url?.endsWith('/questionnaire-observation-value')));
        this.isLink = this.checkIsLink();
        this.isResponseList = this.checkResponseList();
        this.isNursITHidden = this.checkNursITHidden();
        if (typeof QItem.qList === "undefined") QItem.qList = await QuestionnaireService.GetQuestionnaireIds();
        if (ConfigService.Debug && this.item?.linkId)
            window[this.item.linkId] = this;
    }

    checkNursITHidden(): boolean {
        if (!this.item || !this.item.extension) {
            return false;
        }

        const ext = this.item.extension.find(o => o.url.endsWith('/questionnaire-invisible-field'));
        if (ext && typeof ext.valueBoolean) {
            return ext.valueBoolean;
        }

        return false;
    }

    async openGrafixx(event) {
        event.preventDefault();
        const is3dBody = Boolean(ConfigService.GetFormSettings('wounds')?.settings?.body3d?.enabled);

        if (this.isGrafixxItem) {
            this.dialogService.open({
                viewModel: is3dBody ? Modal3dBody : ModalBodyMap, model: {
                    patient: this.patient,
                }, centerHorizontalOnly: is3dBody,
            }).whenClosed((result) => {
                if (!result.wasCancelled) {
                    //this.loadObservations();
                    this.notifier.notify(this.item);
                }
            });
        }

        return false;
    }

    checkIsGrafixxItem(item: any): boolean {
        if (!item) return false;

        if (this.item.extension) {
            let extGrafixx = this.item.extension.find(o => String(o.url).endsWith('questionnaire-grafixx') && typeof o.valueString === "string");
            if (extGrafixx) {
                this.item.initialCoding = {
                    code: extGrafixx.valueString
                }
            }
        }

        return item.initialCoding
            && item.initialCoding.code
            && item.initialCoding.code.toUpperCase().indexOf("GRAFIXX_") > -1;
    }

    registerChild(item) {
        this.children.push(item);
    }

    afterCleared() {
        //alert("cleared")
    }

    async setDefaultGroupValues(doForceRefresh: boolean = true) {
        if (this.readonly) return;
        let preset;

        const presetExtension = Fhir.Tools.GetOrCreateExtension(this.response, '/response-preset', false);
        if (presetExtension?.valueString) {
            preset = QItem.Presets.find(o => o.name === presetExtension.valueString);
            if (!preset) {
                const url = `config/ward_configs/presets/${presetExtension.valueString}`;
                try {
                    if (ConfigService.Debug)
                        console.debug(`Loading preset "${presetExtension.valueString}"`);

                    const httpResult = await new HttpClient().get(url);
                    if (httpResult?.statusCode.toString().startsWith("20")) {
                        // first try to parse the response, rather than just pushing it to avoid invalid json:
                        const js = JSON.parse(httpResult.response);
                        preset = {name: presetExtension.valueString, response: <any>{id: presetExtension.valueString, item: js}, valid: true};
                        QItem.Presets.push(preset);
                    }
                } catch (e) {
                    console.warn(e);
                    preset = {name: presetExtension.valueString, response: undefined, valid: false};
                }
            }
        }

        if (this.item?.item) {
            for (const subItem of this.item.item) {
                // this.item.item.forEach(subItem => {
                if (!this.readonly && !subItem.readOnly) {
                    let valueItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, subItem.linkId);
                    if (valueItem) {
                        // use undefined as response in GetInitialValue to get the QuestionnaireResponse and not the previous response value
                        let newValue;

                        if (preset?.valid && preset?.response) {
                            // get the initial value from the questionnaire itself:
                            const questionnaireInitialValue = Fhir.Questionnaire.GetInitialValue(subItem, undefined)
                            // take the  value from the preset if a valid one was selected
                            newValue = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(preset.response, subItem.linkId, questionnaireInitialValue);
                        } else {
                            // when not, take the value from the questionnaire itself
                            const qItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, subItem.linkId);

                            // this is R4 version, initial is an array:
                            if (NitTools.IsArray(qItem.initial)) {
                                valueItem.answer = NitTools.Clone(qItem.initial);
                                continue;
                            }

                            // this is for Fhir R3 like in VHNOE
                            newValue = Fhir.Questionnaire.GetInitialValue(subItem, undefined); // subItem.initialCoding && subItem.initialCoding.code ? subItem.initialCoding.code : subItem.linkId + "_nil";
                            if (NitTools.IsArray(newValue)) {
                                newValue = newValue[0].valueCoding.code
                            }
                        }

                        let isCalculated = subItem.extension && subItem.extension.findIndex(o => o.url.endsWith('questionnaire-calculated-field')) > -1;

                        if (isCalculated) {
                            if (ConfigService.Debug)
                                console.debug(`Not setting value for ${subItem.linkId} because it is a calculated field`);
                        } else {
                            valueItem.answer = [];
                            let coding: any = {};

                            switch (subItem.type) {
                                case 'boolean':
                                    coding.valueBoolean = NitTools.ParseBool(newValue);
                                    break;
                                case 'decimal':
                                    if (typeof newValue !== 'number')
                                        newValue = parseFloat(newValue);
                                    coding.valueDecimal = newValue;
                                    break;
                                case 'integer':
                                    if (typeof newValue !== 'number')
                                        newValue = parseInt(newValue);
                                    coding.valueInteger = newValue;
                                    break;
                                case 'date':
                                    if (typeof newValue === "string") {
                                        const d = new Date(newValue);
                                        const js = d.toJSON();
                                        if (js.indexOf('T') > -1)
                                            newValue = js.split('T')[0];
                                    }

                                    coding.valueDate = newValue;
                                    break;
                                case 'time':
                                    if (typeof newValue === "string") {
                                        const t = new Date(newValue);
                                        const js = t.toJSON();
                                        if (js.indexOf('T') > -1)
                                            newValue = js.split('T')[1];
                                    }

                                    coding.valueDate = newValue;
                                    break;
                                case 'string':
                                case 'text':
                                    coding.valueString = String(newValue);
                                    break;
                                case 'url':
                                    coding.valueUri = String(newValue);
                                    break;
                                case 'reference':
                                    coding.valueReference = {reference: String(newValue), display: subItem.text};
                                    break;
                                case 'choice':
                                case 'open-choice':
                                default:
                                    coding.valueCoding = {
                                        code: String(newValue)
                                    }

                                    break;
                            }

                            valueItem.answer.push(coding);
                            const valueCheck = QuestionnaireResponse.GetResponseItemValue(valueItem);

                            if (!valueCheck || valueCheck === "undefined")
                                valueItem.answer = [];
                        }
                    }
                }
            }

            if (doForceRefresh)
                this.forceRefresh();
        }
    }

    forceRefresh() {
        if (!this.response) return;
        /*const backup = this.response;
        this.response = undefined;*/

        window.setTimeout(() => {
            // this.response = backup;

            if (typeof this.changed === "function")
                this.changed(this); // should reach questionnaire.ts::onValueChanged($item)
        }, 150);
    }

    setPreviousGroupValues() {
        if (!this.item || !this.previousresponse || !this.response || this.readonly) return;
        this.item.item.forEach(subItem => {
            let valueItem: any = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, subItem.linkId);
            let prevValueItem: any = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.previousresponse, subItem.linkId);
            if (prevValueItem) {
                // let prevValue = Fhir.getFirstItemAnswer(prevValueItem);
                if (valueItem) {
                    // Fhir.setItemValue(valueItem, prevValue);
                    if (typeof prevValueItem.answer !== "undefined") {
                        valueItem.answer = NitTools.Clone(prevValueItem.answer);
                        this.notifier.notify(valueItem);
                    } else {
                        valueItem.answer = [];
                    }
                }
            }
        });

        this.forceRefresh();
    }

    clearGroupValues() {
        if (!this.item || !this.response || this.readonly) return;
        this.item.item.forEach(subItem => {
            let valueItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, subItem.linkId);
            if (valueItem) {
                valueItem.answer = [];
            }
        });

        this.forceRefresh();
    }

    toggleInfoDisplay(linkId?: string, visible?: boolean) {
        let ele = document.querySelector(`.item-info-text[data-info-link-source="${linkId || this.item.linkId}"]`);
        if (!ele) return;

        if (visible === false) ele.classList.remove('in');
        else if (visible === true) ele.classList.add('in');
        else ele.classList.toggle('in');
    }

    checkIsLink(): boolean {
        if (!this.item || this.item.type !== "display") return false;
        if (this.item.extension && this.item.extension) {
            let ext = this.item.extension.find(o => o.url && o.url.toUpperCase().indexOf('QUESTIONNAIRE-SUPPORTLINK') > -1);
            if (typeof ext !== "undefined") {
                this.linkUrl = ext.valueUri;
                return true;
            }
        }

        return false;
    }

    updateHeaderButtons() {
        // for this QRs we don't want Headerbuttons
        let bi = "Questionnaire/" + QItem.qList.QBarthelIndexId;
        let biName = "/" + QuestionnaireService.GetQuestionnaireDirect(QItem.qList.QBarthelIndexId)?.name || 'XXX';
        let biEx = "Questionnaire/" + QItem.qList.QBarthelIndexExId;
        let biExName = "/" + QuestionnaireService.GetQuestionnaireDirect(QItem.qList.QBarthelIndexExId)?.name || 'XXX';
        let fim = "Questionnaire/" + QItem.qList.QFimId;
        let fimName = "/" + QuestionnaireService.GetQuestionnaireDirect(QItem.qList.QFimId)?.name || 'XXX';
        let verl = "Questionnaire/" + QItem.qList.QVerlegungId;
        let verlName = "/" + QuestionnaireService.GetQuestionnaireDirect(QItem.qList.QVerlegungId)?.name || 'XXX';
        let diagnose = "Questionnaire/" + QItem.qList.QDiagnosisId;
        let diagnoseName = "/" + QuestionnaireService.GetQuestionnaireDirect(QItem.qList.QDiagnosisId)?.name || 'XXX';
        this.hasHeaderButtons = [bi, biName, biEx, biExName, fim, fimName, verl, verlName, diagnose, diagnoseName].indexOf('Questionnaire/' + (this.response.questionnaire?.reference || "")) === -1;
    }

    checkVisibility() {
        let extension = Fhir.Tools.GetOrCreateExtension(this.response, 'questionnaire-group-visible/' + this.item.linkId, false);
        if (extension) {
            this.isVisible = extension.valueBoolean;
            return this.isVisible;
        }

        return true;
    }

    checkIsPipeItem() {
        if (!this.item) return false;

        if (this.item.extension) {
            let extPipe = this.item.extension.find(o => o.url.endsWith('questionnaire-pipe-field') && typeof o.valueString === "string");
            if (extPipe && extPipe.valueString.indexOf('.') > -1) {
                return true;
            }
        }

        if (this.item && this.item.initialCoding && this.item.initialCoding.code) {
            let s = this.item.initialCoding.code.replace(/ /g, '').toUpperCase();
            return (s.indexOf('|') === 0 && s.indexOf('.') > 5);
            /* {
              return s.startsWith('=ENCOUNTER.') || s.startsWith('=PATIENT.') || s.startsWith('=RESPONSE.')
            } */
        }
    }

    updateItem() {
        if (!this.item) {
            this.caption = "";
            return;
        }

        if (!this.item.text) {
            this.item.text = "";
        }

        if (!this.item.linkId) {
            this.item.linkId = NitTools.UidName();
        }

        this.item.linkId = this.item.linkId.replace(/ /g, "").replace(/,/g, "").replace(/\//g, "").replace(/-/g, "");
        this.caption = this.item.text;
        this.caption.replace(/\r\n/g, "<br />");

        // when debugging locally add the ID of the control to the label:
        if (window.location.href.indexOf('showLinkIds=1') > -1) {
            this.caption = `<span style="font-size:0.8em; opacity:0.8">[${this.item.linkId}]</span><br /> ${this.caption}`;
        }

        let qualificationFulfilled = true;

        if (this.item.type === "group" && this.item.extension) {
            // check for header color
            this.item.groupColor = 'inherited';
            const colorExtension = Fhir.Tools.GetOrCreateExtension(this.item, '/group-color', false);
            if (colorExtension?.valueString) {
                this.item.groupColor = colorExtension.valueString;
            }

            // check for qualifications
            const qualificationsExtension = Fhir.Tools.GetOrCreateExtension(this.item, '/qualifications', false);
            if (qualificationsExtension && qualificationsExtension.valueString?.trim()) {
                qualificationFulfilled = false; // when a quali is specified, default to not having the quali

                this.item.qualifications = qualificationsExtension.valueString.trim().split(',');
                if (UserService?.Practitioner && UserService?.Qualifications) {
                    for (let i = 0; i < this.item.qualifications.length; i++) {
                        const curItemQualification = this.item.qualifications[i];
                        if (UserService.Qualifications.indexOf(curItemQualification) > -1) {
                            qualificationFulfilled = true;
                            break;
                        }
                    }
                }
            }
        }

        this.item.qualificationFulfilled = qualificationFulfilled;
        if (this.item.item)
            for (const i of this.item.item)
                i.qualificationFulfilled = qualificationFulfilled;
    }

    checkResponseList(): boolean {
        if (!this.item || !this.item.extension) return false;
        let ext = this.item.extension.find(o => o.url.endsWith('response-list'));
        return !!ext;
    }

    private getClassFromControl(newValue): any {
        if (newValue?.au?.controller?.viewModel)
            newValue = newValue.au.controller.viewModel;
        else newValue = undefined;

        if (newValue?.multiSelect?.au?.controller?.viewModel)
            newValue = newValue.multiSelect.au.controller.viewModel;
        else if (newValue?.comboBox?.au?.controller?.viewModel)
            newValue = newValue.comboBox.au.controller.viewModel;

        return newValue
    }

    private getChildItems(parentItem: CiwQuestionnaireItem): string[] {
        const result = [parentItem.linkId];
        if (parentItem.type === 'group') {
            for (const child of parentItem.item) {
                result.push(...this.getChildItems(child));
            }
        }

        return result;
    }
}
