import {Coding, Extension, QuestionnaireItemOption, Reference} from "fhir/r3";

export class CiwQuestionnaireItem implements fhir3.QuestionnaireItem {
    _id: Element | undefined;
    _linkId: Element | undefined;
    _prefix: Element | undefined;
    _readOnly: Element | undefined;
    _repeats: Element | undefined;
    _required: Element | undefined;
    _text: Element | undefined;
    _type: Element | undefined;
    extension: Extension[] | undefined;
    id: string | undefined;
    linkId: string;
    maxLength: number | undefined;
    modifierExtension: Extension[] | undefined;
    option: QuestionnaireItemOption[] | undefined;
    options: Reference | undefined;
    prefix: string | undefined;
    readOnly: boolean | undefined;
    repeats: boolean | undefined;
    required: boolean | undefined;
    text: string | undefined;
    type: "group" | "display" | "question" | "boolean" | "decimal" | "integer" | "date" | "dateTime" | "time" | "string" | "text" | "url" | "choice" | "open-choice" | "attachment" | "reference" | "quantity";
    initialCoding: Coding;
    initial: any[];
    item: CiwQuestionnaireItem[];
    groupColor?: string;
    qualifications?: string[];
    qualificationFulfilled?: boolean;
}
