import {HttpClient} from "aurelia-http-client";
import {I18N} from "aurelia-i18n";
import {inject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {FhirService} from "../services/FhirService";
import {ReportService} from "../services/ReportService";
import {NitTools} from "../classes/NursitTools";
import {ConfigService} from "../services/ConfigService";

@inject(I18N, DialogController)
export class questionnaireInfomations {
    questionnaire: any;
    response: any;
    report: string;
    controller: DialogController;
    questionnaireVersionQR = undefined; // holds the Questionnaire-Version the Response was stored with
    questionnaireVersionQ = undefined;  // holds the current Questionnaire-Version
    questionnaireDate = undefined;
    changedBy = '?';
    i18n: I18N;
    fhirLink = '';
    lastUpdated: Date;
    authored: Date;
    responseVersionId: string;
    status: string;
    reportVersion;
    reportInfoName;
    reportChanged;
    reportAuthor;
    reportCreated;
    debugInfo: string;
    debug: boolean = ConfigService.Debug;
    creator: string;

    constructor(i18n, dialogController) {
        this.controller = dialogController;
        this.i18n = i18n;
    }

    async gatherData() {
        this.fhirLink = `${FhirService.Endpoint}/${this.response.resourceType}/${this.response.id}`;
        this.lastUpdated = this.response.meta && this.response.meta.lastUpdated ? new Date(this.response.meta.lastUpdated) : undefined;
        this.authored = this.response.authored ? new Date(this.response.authored) : undefined;
        this.responseVersionId = this.response.meta && this.response.meta.versionId ? this.response.meta.versionId : '?';
        this.status = this.i18n.tr(this.response.status);

        if (this.response.author) {
            if (this.response.author.display)
                this.creator = this.response.author.display;
            else if (this.response.author.reference) {
                let fs = new FhirService();
                let prac = <any>await fs.get(this.response.author.reference);
                if (prac) {
                    let display = undefined;
                    if (prac.name) {
                        let name = prac.name.find(o => o.use === 'official');
                        if (!name) name = prac.name.find(o => o.use === 'usual');
                        if (!name) name = prac.name[0];
                        if (name) {
                            display = name.family + ', ' + name.given?.join(' ');
                        }
                    }

                    this.creator = display || this.i18n.tr('unknown');
                }
            }
        }

        if (this.response) {
            if (this.response.extension) {
                let versionExtension = this.response.extension.find(o => o.url.endsWith('questionnaire-version'));
                if (versionExtension) {
                    this.questionnaireVersionQR = versionExtension.valueId;
                }
            }
        }

        this.questionnaireVersionQ = this.questionnaire.version || this.i18n.tr('unknown');

        if (this.questionnaire && this.questionnaire.meta && this.questionnaire.meta.versionId) {
            let tmp = [this.questionnaireVersionQ, `versionId: ${this.questionnaire.meta.versionId}`];
            this.questionnaireVersionQ = tmp.join(', ');
        }

        this.questionnaireDate = this.questionnaire.meta.lastUpdated ? new Date(this.questionnaire.meta.lastUpdated).toLocaleString() : '-';
        if (this.questionnaire.extension) {
            let updaterExtension = this.questionnaire.extension.find(o => o.url.endsWith('last-editor'));
            if (updaterExtension) this.changedBy = updaterExtension.valueString;
        }

        this.loadReportVersion();
    }

    loadReportVersion() {
        if (!ReportService.ReportServer) {
            let container = document.querySelector(".document-report-info-content");
            if (container) {
                container.innerHTML = `<div class="alert alert-danger" role="alert"><b>${this.i18n.tr("information")}</b><br />No Report Server specified in config</div>`;
            }

            return;
        }

        new HttpClient().get(NitTools.ExcludeTrailingSlash(ReportService.ReportServer) + "/api/Version/" + this.report)
            .then(response => {
                let js = typeof response.content === "object" ? response.content : JSON.parse(response.content);
                if (!js.error) {
                    this.reportVersion = js.version !== "?" ? js.version : this.i18n.tr("unknown");
                    this.reportInfoName = js.title;
                    this.reportCreated = js.created ? new Date(js.created).toLocaleString() : '?';
                    this.reportChanged = js.updated ? new Date(js.updated).toLocaleString() : '?';
                    this.reportAuthor = js.creator;
                } else {
                    let container = document.querySelector(".document-report-info-content");
                    if (container) {
                        container.innerHTML = `<div class="alert alert-danger" role="alert"><b>${this.i18n.tr("error")}</b><br />${js.error}</div>`;
                    } else {
                        console.warn(js.error);
                    }
                }
            })
    }

    async activate(options) {
        this.questionnaire = options.questionnaire;
        this.response = options.response;
        this.report = ReportService.ReportServer ? options.report : undefined;
        this.debugInfo = options.debugInfo;

        await this.gatherData();
    }
}
