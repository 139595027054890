import {autoinject, bindable} from "aurelia-framework"
import {DialogController} from "aurelia-dialog";
import {FhirService} from "../services/FhirService";
import {ConfigService} from "../services/ConfigService";
import {HttpClient} from "aurelia-http-client";
import {NitTools} from "../classes/NursitTools";
import {HomeList} from "../../views/list";
import {DialogMessages} from "../services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {UserService} from "../services/UserService";
import {EncounterView} from "../../views/encounter";

@autoinject
export class TakeOfflineList {
    @bindable items: any[]
    dialogBody: HTMLElement;
    isWorking: boolean = false;
    currentStatus: string = 'Arbeite...';
    inError: boolean = false;
    errorMessage: string = undefined;
    patientList: HomeList = undefined;
    isOffline: boolean = false;
    checkingAlive: boolean = true;

    constructor(protected controller: DialogController, protected fhirService: FhirService, protected dialogMessages: DialogMessages, protected i18n: I18N) {
    }

    async activate(params) {
        this.items = params.items;
        this.patientList = params.list;
        if (this.items) this.items.forEach(item => item.selected = false);
        this.isOffline = FhirService.OfflineClientSettings && FhirService.OfflineClientSettings.enabled ? FhirService.OfflineClientSettings.isOffline : false;
        this.checkAlive().then(async alive => {
            this.checkingAlive = false;
            if (!alive.success) {
                this.errorMessage = 'Could not reach Fhir-Server at: <br /><b>' + alive.message + '</b><br />Check the Serverside-Offline-Settings and ensure your Local-Fhir-Server is running!';
                this.inError = true;
            } else {
                if (!this.isOffline) await UserService.EnsureUserExistsLocal();
            }
        })
    }

    checkItem(item) {
        if (this.isWorking) return;
        if (item.isOffline) {
            item.selected = false;
            return;
        }

        item.selected = !item.selected;
    }

    async movePatients() {
        this.isWorking = true;
        try {
            if (FhirService.OfflineClientSettings.isOffline) {
                await this.movePatientsOnline();
            } else {
                await this.movePatientsOffline()
            }

            // if (this.patientList) await this.patientList.refreshWard();

            if (FhirService.OfflineClientSettings.isOffline && window.location.href.indexOf('/encounter/') > -1) {
                let sa = window.location.href.split('encounter');
                window.location.href = NitTools.ExcludeTrailingSlash(sa[0]) + '/encounter';
            }
        } catch (e) {
            console.warn(e)
        } finally {
            this.isWorking = false;
        }
    }

    async movePatientsOnline() {
        // if (this.items.filter(o=>o.selected).length === 0) return;
        let putOnlineItems = this.items.filter(o => o.selected === true);
        if (putOnlineItems.length === 0) return;

        for (let i = 0; i < putOnlineItems.length; i++) {
            await FhirService.CopyPatientToOnlineServer(putOnlineItems[i], (status) => {
                this.parseStatus(putOnlineItems, status);
            });

            let localItem = this.items.find(o => o.id === putOnlineItems[i].id);
            if (localItem) {
                localItem._isOffline = false;
                localItem.selected = false;
            }
        }

        await this.patientList.refreshWard();
        this.items = this.patientList.patients;

        this.isWorking = false;

        if (this.items.length === 0) this.controller.close(true).catch(err => console.warn(err));

        if (!this.inError) {
            let encounterCount = await this.fhirService.count('Encounter');
            if (encounterCount === 0) {
                this.dialogMessages.dialog('Keine lokalen Patienten mehr vorhanden.<br />Wollen Sie wieder in den Online-Modus wechseln?', this.i18n.tr('confirm'),
                    this.i18n.tr('yes'), this.i18n.tr('no'), true)
                    .whenClosed(result => {
                        if (!result.wasCancelled) EncounterView.SwitchClient();
                    })
                    .catch(error => console.warn(error));
            }
        }
    }

    parseStatus(takeOfflineItems, status) {
        if (ConfigService.Debug) console.debug("STATUS:", status);

        if (status.patientId) {
            let item = takeOfflineItems.find(o => o.id === status.patientId || o.encounterId === status.patientId);
            if (item) {
                item.text = status.text;
                item.status = status.reason;
            }
        } else {
            this.currentStatus = status.text;
            if (status.reason === 'started') this.isWorking = true;
            else if (status.reason === 'finished') {
                takeOfflineItems.forEach(offlineItem => {
                    let item = this.items.find(o => o.id === offlineItem.id);
                    item.finished = true;
                })
            } else if (status.reason === 'error') {
                takeOfflineItems.forEach(oItem => {
                    let item = this.items.find(o => o.id === oItem.id);
                    item.finished = false;
                })

                this.inError = true;
                this.errorMessage = status.text;
            }
        }
    }

    async checkAlive(): Promise<{ success: boolean, message: string }> {
        let client = new HttpClient();
        client.configure(x => {
            x.withTimeout(5000); // 10 seconds
        })

        let resultObject = {success: true, message: undefined}

        let url = `${NitTools.ExcludeTrailingSlash(FhirService.OfflineClientSettings.local.fhirServer)}/endpoint-health`;
        resultObject.message = url;
        try {
            await client.get(url);
            resultObject.success = true;
        } catch (httpError) {
            resultObject.success = false;
        }

        return resultObject;
    }

    async movePatientsOffline() {
        let takeOfflineItems = this.items.filter(o => o.selected === true && !o._isOffline);
        if (takeOfflineItems.length > 0) {
            let encounterList = await FhirService.CopyPatientsToLocalServer(takeOfflineItems, (status) => {
                this.parseStatus(takeOfflineItems, status);
            })

            encounterList.forEach(encounterId => {
                let item = this.items.find(o => o.encounterId === encounterId);
                if (item) {
                    item._isOffline = true;
                    item.selected = false;
                }

                let patientListItem = (<any[]>this.patientList.patients).find(o => o.encounterId === encounterId);
                if (patientListItem) patientListItem._isOffline = true;
            })
        }

        this.isWorking = false;

        if (!this.inError) {
            this.dialogMessages.dialog('Die ausgewählten Fälle wurden auf den lokalen Rechner kopiert.<br />Wollen Sie in den Offline-Modus wechseln?', this.i18n.tr('confirm'),
                this.i18n.tr('yes'), this.i18n.tr('no'), true)
                .whenClosed(result => {
                    if (!result.wasCancelled) EncounterView.SwitchClient();
                })
                .catch(error => console.warn(error));
        }
    }

    attached() {
        this.dialogBody.style.height = `${document.body.clientHeight - 200}px`;
    }
}
