import {bindable, bindingMode} from "aurelia-framework"
import {NitTools} from "../classes/NursitTools";

export class textInput {
    @bindable({defaultBindingMode: bindingMode.twoWay}) value: string;
    @bindable caption: string;
    @bindable type: string;
    @bindable name: string;
    @bindable maxlength: number;
    @bindable required: boolean;
    @bindable active: boolean;
    textId: string;

    constructor() {
        this.textId = NitTools.UidName();
    }

    attached() {
        if (!this.maxlength) this.maxlength = 4096;
    }
}
