import {bindable} from "aurelia-framework";
import * as Fhir from "../../classes/FhirModules/Fhir";

export class qTime {
    @bindable response: any;
    @bindable item: any;
    @bindable changed: Function;
    @bindable readonly : boolean;
    @bindable placeholder : any;
    @bindable mobileDate: any;
    @bindable hour: number = undefined;
    @bindable minute: number = undefined;

    responseItem: any = undefined;

    get time(): string {
        if (this.responseItem && this.responseItem.answer && this.responseItem.answer[0] && this.responseItem.answer[0].valueTime) {
            let s = String(this.responseItem.answer[0].valueTime);
            if (s.indexOf('T') > -1) {
                s = s.split('T')[1];
                if (s.indexOf('.') > -1) s = s.split('.')[0];
                let sa = s.split(':');
                s = `${sa[0]}:${sa[1]}`;
            }

            return s;
        }

        return '';
    }

    set time(val: string) {
        if (!this.responseItem) return;
        if (!val || typeof val === 'undefined' || val === '')
            delete this.responseItem.answer;
        else
            this.responseItem.answer = [
                {valueTime: val}
            ];

        if (this.item) this.responseItem.text = this.item.text;

        if (typeof this.changed === "function") this.changed(this.responseItem);
    }

    get isReadonly() {
        if (this.item && this.item.readOnly) return true;
        // return from form
        return this.readonly;
    }

    itemChanged() {
        this.checkResponseItem();
    }

    responseChanged() {
        this.checkResponseItem();
    }
/*
    private makeTwo(num: number): string {
        let s = String(num);
        if (s.length < 2) {
            s = "0" + s;
        }

        return s;
    }
*/
    checkResponseItem() {
        this.responseItem = undefined;

        if (this.response && this.item) {
            this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
            if (this.responseItem && this.responseItem.answer && this.responseItem.answer[0] && this.responseItem.answer[0].valueTime)
                this.time = this.responseItem.answer[0].valueTime;
        }
    }

    dpOptions = {
        format: 'HH:mm', // this.format,
        defaultDate: false,
        showClose: true,
        showClear: true,
        inline: false,
        stepping: 1,
        toolbarPlacement: 'bottom',
        icons: {close: 'glyphicon glyphicon-ok'}
        // see App.setPickerOptions / App.pickerConfig
    };
}
