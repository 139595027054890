import {NitTools} from "../../classes/NursitTools";

export class qChangeNotifier {
    private subscribers: any[] = [];

    notify(item : any) {
        this.subscribers.forEach(sub => {
            if (sub.event) {
                sub.event(item, this, sub.id);
            }
        });
    }

    subscribe(event: Function): string {
        let id = NitTools.Uid();
        this.subscribers.push({id: id, event: event});

        return id;
    }

    unsubscribe(id: string) {
        let item = this.subscribers.find(o => o.id === id);
        if (!item) return;
        let idx = this.subscribers.indexOf(item);

        if (idx > -1) {
            this.subscribers.splice(idx, 1);
        }
    }
}
