import {BasicForm} from './../BasicForm';
import {bindable} from "aurelia-framework";
import {IQuestionnaireDialogSettings, QuestionnaireDialogContainer} from "./questionnaire-dialog-container";
import {DialogService} from "aurelia-dialog";
import {translations} from "../../classes/translations";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {PatientService} from "resources/services/PatientService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import * as environment from "../../../../config/environment.json";
import {FhirService} from "../../services/FhirService";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import {fhirEnums} from "../../classes/fhir-enums";
import {NitTools} from "../../classes/NursitTools";
import {DialogMessages} from "../../services/DialogMessages";
import {UserService} from "../../services/UserService";
import {ReportService} from "../../services/ReportService";
import {ConfigService} from "../../services/ConfigService";
import {I18N} from "aurelia-i18n";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

export class qReference {
    static inject = [DialogService, PatientService, FhirService, I18N];
    patientService: PatientService;

    @bindable item: any;
    @bindable encounter;
    @bindable refclicked;
    @bindable response: any;

    i18n : I18N;
    link: string = undefined;

    constructor(dialogService, patientService, fhirService, i18n) {
        this.dialogService = dialogService;
        this.patientService = patientService;
        this.fhirService = fhirService;
        this.i18n = i18n;
    }

    appenderClass: string = 'mdi-add';
    hasExistingFall: boolean = false;

    responseChanged(r: any) {
        this.getLink();
        this.hasExistingFall = false;
        if (!this.popupPatient) this.popupPatient = this.patientService.latestPatient;
        if (r && this.popupPatient) {
            // this response is the assessment. Now we have to search the "fall" responses..
            const path = this.getLinkPath();
            if (path) {
                const q = QuestionnaireService.GetQuestionnaireByNameDirect(path)
                if (q) {
                    const r = QuestionnaireService.GetLatestResponseOfName(this.popupPatient, q.name, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]);
                    if (r) {
                        this.hasExistingFall = !!r;
                    }
                }
            } else {
                let fallResponses = this.popupPatient.questionnaireResponses.filter(o => o && o.questionnaire && o.questionnaire.reference &&
                    o.questionnaire.reference?.indexOf(`/${QuestionnaireService.__listResult.QFallId}`) > -1 && o.extension);
                // .. and try to find a matching fall-response for the current assessment.
                // this is linked in an extension with an url ending with "link-assessment-response".
                // So let's get the first fall response which linked id matches the id of the current response/assessment..

                fallResponses.forEach(fall => {
                    if (!this.hasExistingFall) {
                        let ext = Fhir.Tools.GetOrCreateExtension(fall, 'link-assessment-response', false);

                        if (ext) {
                            let linkId = Fhir.Tools.GetExtensionValue(ext);
                            if (linkId.indexOf('/') > -1) linkId = linkId.split('/')[1];
                            if (linkId === r.id) {
                                this.hasExistingFall = true;
                            }
                        }
                    }
                })
            }
        }

        QuestionnaireService.GetQuestionnaireIds()
        .then((qList: IQuestionnaireList) => {
            let qId: string = undefined;
            let path = this.getLinkPath();
            switch (path) {
                case "anamnese":
                    qId = qList.QAnamnesisId;
                    break;
                case "assessment":
                    qId = qList.QAssessmentId;
                    break;
                case "wunden":
                    qId = qList.QWoundId;
                    break;
                case "fall":
                    qId = qList.QFallId;
                    break;
                default:
                    if (path)
                        qId = QuestionnaireService.GetQuestionnaireByNameDirect(path)?.id;
                    break;
            }

            if (!qId) return;

            let popupResponses: any[] = QuestionnaireService.GetResponsesOfType(PatientItem.LastLoadedPatient, qId);

            for (let i = 0; i < popupResponses.length; i++) {
                if (popupResponses[i].extension) {
                    let ext = popupResponses[i].extension.find(o => o.url.endsWith('link-assessment-response'));
                    if (ext && ext.valueUri && ext.valueUri.endsWith(r.id)) {
                        break;
                    }
                }
            }
        });

    }

    getLink() {
        this.link = this.getLinkPath();
        this.url = `#/encounter/${this.encounter}/${this.link}`;
    }

    // dialog popups
    dialogService: DialogService;
    popupSettings: IQuestionnaireDialogSettings = undefined;
    popupResponse: any;
    popupQuestionnaire: any;
    popupPatient: PatientItem;
    popupStatus: string;
    fhirService: FhirService;

    printSavedResponse() {
        if (!this.popupResponse || this.popupResponse.id === '-1' || !this.popupQuestionnaire) return;
        if (this.popupQuestionnaire.id === QuestionnaireService.__listResult.QFallId) {
            console.debug("Sending AutoSave for Fall " + this.popupResponse.id);
            let setting = ConfigService.GetFormSettings("FALL");
            if (setting && setting.report && setting.report.name) {
                let report = setting.report.autoSaveReportName || setting.report.name;
                ReportService.SendAutoSave(this.popupResponse.id, report, this.patientService.latestPatient);
            }
        }
    }

    modalWindowSaveClicked() {
        if (!this.popupResponse) return;
        let that = this;

        try {
            if (that.popupResponse.id === QuestionnaireService.__listResult.QFallId) {
                that.popupResponse.status = "completed";
            }
        } catch (e) {
            console.warn(e.message);
        }

        if (that.popupResponse.id === "-1") {
            console.debug("Creating new Response");
            that.fhirService.create(that.popupResponse)
                .catch(error => {
                    DialogMessages.Prompt(that.dialogService, error, "Error when creating new Response", true);
                })
                .then((result: any) => {
                    that.popupResponse = result;
                    that.patientService.addQuestionnaireResponse(that.patientService.latestPatient, that.popupResponse);
                    this.printSavedResponse();
                })
        } else {
            console.debug("Updating existing Response");
            that.fhirService.update(that.popupResponse)
                .catch(error => {
                    DialogMessages.Prompt(that.dialogService, error, "Error when creating new Response", true);
                })
                .then((result: any) => {
                    that.patientService.addQuestionnaireResponse(that.patientService.latestPatient, that.popupResponse);
                    this.printSavedResponse();
                })
        }
    }

    modalWindowAbortClicked() {
        this.popupResponse = undefined;
    }

    createDefaultFallItems(questionnaireId: string) {
        let that = this;

        /* that.popupResponse = {
            item: [],
            authored: new Date().toJSON(),
            questionnaire: {
                reference: `Questionnaire/${questionnaireId}`
            },
            status: 'in-progress',
            id: '-1'
        }; */
        that.popupResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patientService.latestPatient, questionnaireId, QuestionnaireResponseStatus.inProgress);

        that.popupResponse.extension = [];
        that.popupResponse.id = "-1";

        that.popupResponse.extension.push({
            url: NitTools.IncludeTrailingSlash(environment.nursItStructureDefinition) + 'link-assessment-response',
            valueUri: `QuestionnaireResponse/${this.response.id}`
        });

        // "Zeitpunkt des Sturzes (Datum)"
        let dateItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(that.popupResponse, "101_01", true);

        // "Zeitpunkt des Sturzes (Uhrzeit)"
        let timeItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(that.popupResponse, "101_02", true);
        let d = new Date();

        if (dateItem) {
            dateItem.answer = [{
                valueDate: `${d.getFullYear()}-${NitTools.ToString(d.getMonth() + 1, 2, '0')}-${NitTools.ToString(d.getDate(), 2, '0')}`
            }];
        }

        if (timeItem) {
            timeItem.answer = [{
                valueString: `${NitTools.ToString(d.getHours(), 2, '0')}:${NitTools.ToString(d.getMinutes(), 2, '0')}`
            }];
        }

        // "Sturz aufgenomen am"
        dateItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(that.popupResponse, "105_01", true);
        if (dateItem) {
            dateItem.answer = [{
                valueDate: `${d.getFullYear()}-${NitTools.ToString(d.getMonth() + 1, 2, '0')}-${NitTools.ToString(d.getDate(), 2, '0')}`
            }];
        }

        let durchItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(that.popupResponse, "105_02", true);
        if (durchItem && UserService.IsLoggedIn) {
            durchItem.answer = [{
                valueString: UserService.UserLastName + ', ' + UserService.UserFirstName
            }];
        }

        let alreadyFallenItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(that.popupResponse, "101_03", true);
        let fallCount = QuestionnaireService.GetResponsesOfType(that.patientService.latestPatient, questionnaireId).length;
        if (alreadyFallenItem) {
            alreadyFallenItem.answer = [
                {
                    valueCoding: {
                        code: fallCount > 0 ? '101_03_01' : '101_03_02',
                        display: fallCount > 0 ? 'Ja' : 'Nein'
                    }
                }
            ];
        }
    }

    getLinkPath(): string {
        let link: string = undefined;
        if (!this.item) {
            return undefined;
        }

        const coding = this.item?.code?.find(o=>o.system?.toUpperCase().indexOf('/QUESTIONNAIRE') > -1);
        if (coding?.code) {
            return coding.code;
        }

        let formId: any = -1;
        switch (this.item.linkId) {
            case "BarthelIndex":
                formId = 7222;
                break;
            case "BarthelIndexEx":
                formId = 7223;
                break;
            case "OpenFollDocumentation":
            case "Open Foll Documentation":
                formId = 1357;
                break;
            case "WundenDocumentationWound":
                formId = 1356;
                break;
            case "FIM":
                formId = 7251;
                break;
        }

        let txt = this.item.text || "unbekannter link";
        if (txt) {
            txt = txt.replace(" reference", "");
        }

        if (txt && txt !== "") {
            this.item.text = txt;
        }

        if (this.item.linkId === "WundenDocumentationWound") {
            link = "wunden";
        }

        switch (formId) {

            case "fall":
            case 1357:
                link = "fall";
                break;
            case 1352:
                link = "anamnese";
                break;
            case 1353:
                link = "assessment";
                break;
            case "wound":
                link = "wunden";
                break;
        }

        return link;
    }

    openDialog() {
        let that = this;

        QuestionnaireService.GetQuestionnaireIds()
            .then((qList: IQuestionnaireList) => {
                let questionnaireId: string = undefined;
                let path = this.getLinkPath();
                switch (path) {
                    case "anamnese":
                        questionnaireId = qList.QAnamnesisId;
                        break;
                    case "assessment":
                        questionnaireId = qList.QAssessmentId;
                        break;
                    case "wunden":
                        questionnaireId = qList.QWoundId;
                        break;
                    case "fall":
                        questionnaireId = qList.QFallId;
                        break;
                    default:
                        questionnaireId = QuestionnaireService.GetQuestionnaireByNameDirect(path)?.id;
                        break;
                }

                if (!questionnaireId) return;

                QuestionnaireService.GetQuestionnaireById(questionnaireId)
                    .then((questionnaire: any) => {
                        that.popupQuestionnaire = questionnaire;
                        that.patientService.fetch(that.encounter)
                            .then((patient: PatientItem) => {
                                if (!patient) return;
                                that.popupPatient = patient;

                                that.popupResponse = QuestionnaireService.GetLatestResponseOfType(this.popupPatient, that.popupQuestionnaire.id, []);

                                if (!that.popupResponse) {
                                    that.popupResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, that.popupQuestionnaire, "in-progress");
                                    that.popupResponse.id = '-1';
                                }

                                //#region special handling for "Fall"
                                if (path === "fall") {
                                    that.popupResponse = undefined;

                                    let popupResponses = [];
                                    if (FhirService.FhirVersion <= 3) {
                                        popupResponses = patient.questionnaireResponses.filter(o => o.questionnaire?.reference?.indexOf('Questionnaire/' + questionnaireId) > -1 && o.extension);
                                    } else {
                                        popupResponses = patient.questionnaireResponses.filter(o => String(o.questionnaire).endsWith(questionnaire.name) && o.extension);
                                    }

                                    for (let i = 0; i < popupResponses.length; i++) {
                                        if (popupResponses[i].extension) {
                                            let ext = popupResponses[i].extension.find(o => o.url.endsWith('link-assessment-response'));
                                            if (ext && ext.valueUri && ext.valueUri.endsWith(this.response.id)) {
                                                that.popupResponse = popupResponses[i];
                                                break;
                                            }
                                        }
                                    }

                                    // auto-create new Sturz-Event if none found:
                                    if (!that.popupResponse) {
                                        that.popupResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, questionnaireId, fhirEnums.QuestionnaireResponseStatus.inProgress);
                                        that.popupResponse.extension = [];
                                        that.popupResponse.id = "-1";
                                        that.popupResponse.extension.push({
                                            url: NitTools.IncludeTrailingSlash(environment.nursItStructureDefinition) + 'link-assessment-response',
                                            valueUri: `QuestionnaireResponse/${this.response.id}`
                                        });

                                        this.createDefaultFallItems(questionnaireId);
                                    }
                                }
                                //#endregion

                                that.popupStatus = that.popupResponse ? translations.translate(that.popupResponse.status) : that.popupStatus = "";
                                const previousTitle = BasicForm.pageTitle;

                                that.popupSettings = {
                                    grouplist: false,
                                    response: that.popupResponse,
                                    questionnaire: that.popupQuestionnaire,
                                    encounter: that.popupPatient.encounter,
                                    tooold: false,
                                    haschanges: false,
                                    dialogService: that.dialogService,
                                    status: that.popupStatus,
                                    showToolbar: true,
                                    allowAddNew: false,
                                    showSelection: false,
                                    selectLastResponse: false,
                                    data: {
                                        title: (that.popupQuestionnaire.title || that.popupQuestionnaire.name) + (that.popupResponse.id === '-1' ? ' *' : '')
                                    }
                                };

                                that.dialogService.open({
                                    viewModel: QuestionnaireDialogContainer,
                                    model: that.popupSettings,
                                    lock: true
                                })
                                    .whenClosed(result => {
                                        BasicForm.pageTitle = previousTitle;
                                        if (!result.wasCancelled)
                                            that.modalWindowSaveClicked();
                                        else
                                            that.modalWindowAbortClicked();
                                    })
                                    .catch(e => {
                                        console.warn(e);
                                    });
                            })
                    })
            })
    }

    url: string = "#/";

    encounterChanged() {
        this.getLink();
    }

    itemChanged() {
        this.getLink();
    }
}
